import React, { useEffect, useState } from "react";
import { FlexColumn, FlexRow } from "../../components/Flex";
import { ActionIcon, Menu, RingProgress, Text } from "@mantine/core";
import {
  IconBook,
  IconBooks,
  IconBrain,
  IconBrandFunimation,
  IconCoinBitcoin,
  IconDownload,
  IconFeather,
  IconFlare,
  IconHeartBroken,
  IconHearts,
  IconKarate,
  IconQuestionMark,
  IconSparkles,
  IconWood,
} from "@tabler/icons-react";
import { IconArrowsRandom } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { setPrompt } from "../../store/prompt/promptReducer";
import travel from "../../utils/travel";
import { useNavigate } from "react-router-dom";
import Dictaphone from "../../components/Dictaphone";
import { setStoreText } from "../../store/storeText/storeTextReducer";
import { formatAmount, formatAmountWithComma } from "../../workers/KSeparator";

const Recommendations = ({}) => {
  const currentType = useSelector((state) => state.prompt);
  // const tokensUsed = useSelector((state) => state.activeChat.chat.tokensUsed);
  const user = useSelector((state) => state.user);
  const messages = useSelector((state) => state.messages);
  const [type] = useState(currentType);
  const [tokensUsed, setTokensUsed] = useState(0);
  // let tokensUsed = 0

  const dispatch = useDispatch();

  // const change = (val) => dispatch(setStoreText(val));
  const CalcTokens = () => {
    let temp = 0;
    messages.map(
      (msg) =>
        // setTokensUsed(tokensUsed + msg.tokensUsed)
        // console.log(msg.tokensUsed);
        (temp = temp + msg.tokensUsed)
    );
    // console.log("Temp is:", messages.length);
    setTokensUsed(temp);

    return tokensUsed;
  };

  useEffect(() => {
    if (type?.length) {
      dispatch(setPrompt(type));
    }
    if (messages?.length) {
      CalcTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, messages?.length, type]);

  // console.log("Tokens", tokensUsed);
  // const getPercent = (val, max) => {
  //   let ans = 100 - (val / max) * 100;
  //   return parseFloat(ans.toFixed(0));
  // };

  // const handleDownload = (name) => {
  //   // Function will execute on click of button
  //   // using Java Script method to get PDF file
  //   fetch(name).then((response) => {
  //     response.blob().then((blob) => {
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       // Setting various property values
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = name;
  //       alink.click();
  //     });
  //   });
  // };

  const navigate = useNavigate();
  return (
    <FlexRow style={{ width: "92%", padding: "0px 1em" }}>
      <FlexColumn
        width="auto"
        height="auto"
        style={{
          marginRight: "1em",
          padding: "0.2em 0.4em",
          cursor: "pointer",
        }}
        onClick={() => travel(navigate, "/wallet", {})}
      >
        {/* <Menu
          transitionProps={{ transition: "pop" }}
          withArrow
          position="bottom-start"
          withinPortal
        >
          <Menu.Target>
            <ActionIcon>
              <IconDotsVertical
                size="3rem"
                style={{
                  marginTop: "1em",
                }}
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IconNote size="1rem" stroke={1.5} />}>
              Add Note
            </Menu.Item>
            <Menu.Item icon={<IconReportAnalytics size="1rem" stroke={1.5} />}>
              {user?.tokens - tokensUsed} Tokens Left
            </Menu.Item>
            <Menu.Item
              icon={<IconTrash size="1rem" stroke={1.5} />}
              color="red"
            >
              Delete Chat
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Text style={{ fontSize: "11px", marginTop: "10px" }}>Menu</Text> */}
        <RingProgress
          size={80}
          thickness={6}
          roundCaps
          sections={[
            {
              value: user?.tokens - tokensUsed,
              color:
                user?.tokens - tokensUsed < 12000
                  ? "red"
                  : user?.tokens - tokensUsed < 50000
                  ? "violet"
                  : "green",
            },
          ]}
          label={
            <FlexRow alignItems="flex-start">
              {/* <Text
                color={
                  getPercent(tokensUsed, user?.tokens) < 30
                    ? "red"
                    : getPercent(tokensUsed, user?.tokens) < 60
                    ? "violet"
                    : "green"
                }
                weight={600}
                align="center"
                size="xs"
              >
                {getPercent(tokensUsed, user?.tokens)}%
              </Text> */}
              <Text
                color={
                  user?.tokens - tokensUsed < 12000
                    ? "red"
                    : user?.tokens - tokensUsed < 50000
                    ? "violet"
                    : "green"
                }
                size="s"
              >
                {formatAmount(user?.tokens - tokensUsed)}
              </Text>
              <Text
                size="7px"
                color={
                  user?.tokens - tokensUsed < 12000
                    ? "red"
                    : user?.tokens - tokensUsed < 50000
                    ? "violet"
                    : "green"
                }
              >
                CC
              </Text>
            </FlexRow>
          }
        />
      </FlexColumn>
      {/* <FlexColumn width="auto" height="auto" style={{ marginRight: "1em" }}>
        <Menu
          transitionProps={{ transition: "pop" }}
          withArrow
          position="bottom-start"
          withinPortal
        >
          <Menu.Target>
            <ActionIcon>
              <IconBrain
                size="2.5rem"
                style={{
                  marginTop: "1em",
                }}
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconQuestionMark size="1rem" stroke={1.5} />}
              onClick={() => change("Lets play a game of never have I ever")}
            >
              Never Have I Ever
            </Menu.Item>
            <Menu.Item
              onClick={() => change("Tell Me a Joke")}
              icon={<IconBrandFunimation size="1rem" stroke={1.5} />}
            >
              Tell Me a Joke
            </Menu.Item>
            <Menu.Item
              icon={<IconArrowsRandom size="1rem" stroke={1.5} />}
              onClick={() => change("Generate an Exiting Trivia")}
            >
              Generate an Exiting Trivia
            </Menu.Item>
            <Menu.Item
              icon={<IconBrain size="1rem" stroke={1.5} />}
              onClick={() => change("Generate a Riddle")}
            >
              Generate a Riddle
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Text style={{ fontSize: "11px", marginTop: "10px" }}>Games</Text>
      </FlexColumn>
      <FlexColumn width="auto" height="auto" style={{ marginRight: "1em" }}>
        <Menu
          transitionProps={{ transition: "pop" }}
          withArrow
          position="bottom-start"
          withinPortal
        >
          <Menu.Target>
            <ActionIcon>
              <IconFeather
                size="2.5rem"
                style={{
                  marginTop: "1em",
                }}
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconKarate size="1rem" stroke={1.5} />}
              onClick={() => change("Generate Action Stories")}
            >
              Generate Action Stories
            </Menu.Item>
            <Menu.Item
              icon={<IconWood size="1rem" stroke={1.5} />}
              onClick={() => change("Generate African Stories")}
            >
              Generate African Stories
            </Menu.Item>

            <Menu.Item
              icon={<IconHearts size="1rem" stroke={1.5} />}
              onClick={() => change("Generate Romantic Stories")}
            >
              Generate Romantic Stories
            </Menu.Item>
            <Menu.Item
              onClick={() => change("Generate Tragic Stories")}
              icon={<IconHeartBroken size="1rem" stroke={1.5} />}
            >
              Generate Tragic Stories
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Text style={{ fontSize: "11px", marginTop: "10px" }}>Stories</Text>
      </FlexColumn>
      <FlexColumn width="auto" height="auto" style={{ marginRight: "1em" }}>
        <Menu
          transitionProps={{ transition: "pop" }}
          withArrow
          position="bottom-start"
          withinPortal
        >
          <Menu.Target>
            <ActionIcon>
              <IconSparkles
                size="2.5rem"
                style={{
                  marginTop: "1em",
                }}
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconBooks size="1rem" stroke={1.5} />}
              onClick={() => change("Generate Top Personal Growth Books")}
            >
              Top Personal Growth Books
            </Menu.Item>
            <Menu.Item
              icon={<IconFlare size="1rem" stroke={1.5} />}
              onClick={() => change("Generate the next big innovation")}
            >
              The Next Big Innovation
            </Menu.Item>
            <Menu.Item
              icon={<IconBook size="1rem" stroke={1.5} />}
              onClick={() => change("Generate study tips for IELTS")}
            >
              Study Tips for IELTS
            </Menu.Item>
            <Menu.Item
              icon={<IconCoinBitcoin size="1rem" stroke={1.5} />}
              onClick={() => change("Generate Financial Tips")}
            >
              Financial Tips
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Text style={{ fontSize: "11px", marginTop: "10px" }}>Recommend</Text>
      </FlexColumn> */}
      <FlexColumn width="auto" height="auto" style={{ marginRight: "1em" }}>
        <Dictaphone />
      </FlexColumn>
      {/* <FlexColumn
        width="auto"
        height="auto"
        style={{ marginTop: "0.5em" }}
        alignItems="flex-start"
      >
        <Select
          value={type}
          // onChange={setType}
          onChange={() => {
            successNotify({
              message:
                "Congratulations you just discovered a New Functionality thats not available yet",
              code: "Coming Soon...",
            });
          }}
          placeholder={type}
          styles={(theme) => ({
            item: {
              // applies styles to selected item
              "&[data-selected]": {
                "&, &:hover": {
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.violet[9]
                      : theme.colors.violet[1],
                  color:
                    theme.colorScheme === "dark"
                      ? theme.white
                      : theme.colors.violet[9],
                },
              },

              // applies styles to hovered item (with mouse or keyboard)
              "&[data-hovered]": {
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.violet[9]
                    : theme.colors.violet[1],
                color:
                  theme.colorScheme === "dark"
                    ? theme.white
                    : theme.colors.indigo[9],
              },
            },
          })}
          style={{
            width: "80px",
          }}
          size="xs"
          width="auto"
          data={[
            { value: "text", label: "Text" },
            { value: "image", label: "Image" },
          ]}
        />
        <Text style={{ fontSize: "11px", marginBottom: "5px" }}>Generate?</Text>
      </FlexColumn> */}
      {/* <FlexColumn width="auto" height="auto" style={{ marginRight: "1em" }}>
        <Menu
          transitionProps={{ transition: "pop" }}
          withArrow
          position="bottom-start"
          withinPortal
        >
          <Menu.Target>
            <ActionIcon>
              <IconFlare
                size="2.5rem"
                style={{
                  marginTop: "1em",
                }}
              />
            </ActionIcon>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<IconDownload size="1rem" stroke={1.5} />}
              onClick={() => handleDownload("2023-GCE-AL-Results.pdf")}
            >
              
              Advanced Level 2023
            </Menu.Item>
            <Menu.Item
              icon={<IconDownload size="1rem" stroke={1.5} />}
              onClick={() => handleDownload("2023-GCE-OL-Results.pdf")}
            >
             
              Ordinary Level 2023
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
        <Text style={{ fontSize: "11px", marginTop: "10px" }}>GCE Results</Text>
      </FlexColumn> */}
    </FlexRow>
  );
};

export default Recommendations;
