import React from "react";
import { FlexColumn } from "../../components/Flex";
import logo from "../../assets/Onitsha.gif";
import logoOffline from "../../assets/OnitshaOffline.gif";
import { Text } from "@mantine/core";

const Empty = () => {
  return navigator.onLine ? (
    <FlexColumn height="100vh" style={{ pointerEvents: "none" }}>
      <img src={logo} alt="ChatGDA" width={150} height={150} />
    </FlexColumn>
  ) : (
    <FlexColumn height="100vh" style={{ pointerEvents: "none" }}>
      <img src={logoOffline} alt="ChatGDA" width={150} height={150} />
      <Text>Offline 😰📶</Text>
    </FlexColumn>
  );
};

export default Empty;
