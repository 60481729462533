import { db } from "./firebaseConfig";
import { errorNotify, loadingNotify } from "../header/components/Notify";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { id } from "../utils/idGenerator";
import axios from "axios";

// const { Configuration, OpenAIApi } = require("openai");

// Function to handle sending a message and receiving a response
export const sendMessage = async (data) => {
  loadingNotify({
    state: data.state,
    preCode: "Sending....",
    preMessage: "Please Wait....",
    postCode: "Message Sent!",
    postMessage: "ChatGDA Replying....",
  });
  try {
    // Send the message to the OpenAI API

    // console.log("Messages To Send", data);

    let temp = {};
    let completion = {};
    let forState = {};
    // const jString = JSON.stringify(data);

    const firebaseWork = async (response) => {
      // Handle the response data
      temp = response.data;
      // console.log("Response from Server: ", temp);
      completion = temp.completion;

      // Save the message and response data to Firestore
      // console.log("Creating Message");
      const msI = id();
      const msgID = msI;
      const now = new Date();
      const userMsg = temp.message.messages[temp.message.messages.length - 1];
      // const b4Data = completion.data.choices[0].message.content.replaceAll(
      //   /<|im_sep|>/gi,
      //   " "
      // );
      // const again = completion.data.choices[0].message.content.replace(
      //   /\\n/g,
      //   "\n"
      // );
      const jsonData = JSON.stringify(
        completion.data.choices[0].message.content
      );
      const jsonMsg = JSON.stringify(userMsg.content);
      const final = {
        timestamp: Timestamp.fromDate(now),
        updateDate: Timestamp.fromDate(now),
        // chatID: temp.message.chatID,
        id: msgID,
        isDeleted: false,
        tokensUsed: completion.data.usage.total_tokens,
        userID: temp.message.userID,
        messages: [
          {
            content: jsonMsg,
            role: userMsg.role,
            name: userMsg.name,
          },
          {
            content: jsonData,
            role: completion.data.choices[0].message.role,
            name: "ChatGDA",
          },
        ],
      };
      forState = final;
      // console.log("Final Data Sent to Firebase:", final);
      await setDoc(doc(db, "MESSAGES", final.id), {
        ...final,
      });
      // console.log("Updating Chat");
      // const chatFinal = {
      //   ...data.chat,
      //   lastUseDate: Timestamp.fromDate(now),
      //   tokensUsed: data.chat.tokensUsed + completion.data.usage.total_tokens,
      //   messages: [...data.chat.messages, msgID],
      // };

      // console.log("Final Data Sent to Chat Firebase:", chatFinal);
      // await setDoc(doc(db, "CHATS", chatFinal.id), {
      //   ...chatFinal,
      // });
    };

    // console.log("axios data: ", data);
    await axios
      // .post("https://engine.gdaafrica.com/api/chat", { ...data })
      // .post("https://engine.yousquare.org/api/chat", { ...data })
      // .post("http://localhost:3001/api/chat", { ...data })
      .post("https://chatgda-3oep.onrender.com/api/chat", { ...data })
      // .post("https://api.chatgda.tech/api/chat", { ...data })
      // .post("https://elenango.com/api/chat", { ...data })
      // .post("https://chatgda-server.onrender.com/api/chat", { ...data })
      .then(async (res) => {
        // console.log("axios response: ", res);
        firebaseWork(res);
      });

    // Return the completion data
    data.state = false;
    const ret = { temp, forState };
    return ret;
  } catch (error) {
    // Handle error scenarios
    // console.log("Error:", error);
    errorNotify({
      code: `Sending Error... - ${error.code}`,
      message: error.message,
    });
    throw error;
  }
};
