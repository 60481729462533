import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
//   measurementId: process.env.REACT_APP_MEASUREMENT_ID,
// };
const firebaseConfig = {
  apiKey: "AIzaSyBi_OuszTmjDhfD7vaWkBevoQNGqR__NrQ",
  authDomain: "chatgda2023.firebaseapp.com",
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: "chatgda2023",
  storageBucket: "chatgda2023.appspot.com",
  messagingSenderId: "469036864926",
  appId: "1:469036864926:web:7caca21594110b4777328f",
  measurementId: "G-31GL22FN1S",
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
export const auth = getAuth();
export const db = getFirestore(app);
export default firebaseConfig;
