import { useMantineColorScheme } from "@mantine/core";
import React from "react";

const Heading = ({
  style = {},
  className = "",
  fontSize = 20,
  fontWeight = 400,
  textDecoration = "underline",
  color,
  key = 0,
  onClick = () => {},
  children,
}) => {
  const { colorScheme } = useMantineColorScheme();

  return (
    <div
      style={{
        fontSize: fontSize,
        fontWeight: fontWeight,
        color: color || colorScheme === "dark" ? "#FFF" : "#000",
        textDecoration: textDecoration,
        ...style,
      }}
      className={`heading ${className}`}
      onClick={onClick}
      key={key}
    >
      {children}
    </div>
  );
};

export default Heading;
