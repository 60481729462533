import {
  createStyles,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Button,
  Tooltip,
  Transition,
} from "@mantine/core";
import { IconCoin } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { FlexColumn, FlexRow } from "../../components/Flex";
import View from "../View";
import { useEffect, useState } from "react";
import { modals } from "@mantine/modals";
import { useToggle } from "@mantine/hooks";
import PaymentModal from "../../components/PaymentModal";
import { MonetbilPaymentWidget } from "../../services/monetbil-payment/MonetbilPaymentWidget";
import { formatAmount, formatAmountWithComma } from "../../workers/KSeparator";
import { getMessages } from "../../store/messages/messageJobs";
import { saveMessage } from "../../store/messages/messagesReducer";

const useStyles = createStyles((theme) => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
  },

  label: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },
}));

export function Wallet() {
  const { classes } = useStyles();

  const user = useSelector((state) => state.user);
  const messages = useSelector((state) => state.messages);

  const [tokensUsed, setTokensUsed] = useState(0);

  const [paying, setPaying] = useState(false);

  const CalcTokens = () => {
    let temp = 0;
    messages.map(
      (msg) =>
        // setTokensUsed(tokensUsed + msg.tokensUsed)
        // console.log(msg.tokensUsed);
        (temp = temp + msg.tokensUsed)
    );
    // console.log("Temp is:", messages.length);
    setTokensUsed(temp);

    return tokensUsed;
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (messages?.length) {
      CalcTokens();
    } else {
      if (user?.displayName) {
        getMessages(user.uid).then((res) => {
          if (res) {
            dispatch(saveMessage(res.data));
            // console.log(res);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const tokensLeft = user?.tokens - tokensUsed;
  return (
    <View link="/wallet">
      <FlexColumn
        justifyContent="flex-start"
        style={{
          height: "100%",
          marginTop: "2em",
          width: "100%",
        }}
      >
        <Paper
          withBorder
          p="lg"
          radius="md"
          style={{ padding: "1em", margin: "1em" }}
        >
          <Group position="apart">
            <FlexRow style={{ padding: "0px 1em", width: "60vw" }}>
              <FlexColumn alignItems="flex-start">
                <Text
                  c="dimmed"
                  tt="uppercase"
                  fw={700}
                  fz="xs"
                  className={classes.label}
                >
                  Chat Coin Balance
                </Text>
                <FlexRow justifyContent="flex-start" alignItems="flex-start">
                  <Text fw={700} fz="xl">
                    {tokensLeft < 100000
                      ? formatAmountWithComma(tokensLeft)
                      : formatAmount(tokensLeft)}
                  </Text>
                  <Text fw={600} fz="xs">
                    CC
                  </Text>
                </FlexRow>
              </FlexColumn>
              <ThemeIcon
                color="gray"
                variant="light"
                sx={(theme) => ({
                  color:
                    tokensLeft > 500
                      ? theme.colors.teal[6]
                      : theme.colors.red[6],
                })}
                size={38}
                radius="md"
              >
                <IconCoin size="1.8rem" stroke={1.5} />
              </ThemeIcon>
            </FlexRow>
          </Group>
          <FlexColumn
            width="100%"
            style={{ paddingTop: "1em" }}
            //   alignItems="flex-start"
          >
            <Button
              variant="filled"
              color="violet"
              fullWidth
              disabled={paying}
              onClick={() => setPaying(true)}
            >
              Add Chat Coins
            </Button>
          </FlexColumn>
        </Paper>

        <Transition
          mounted={paying}
          transition="slide-down"
          duration={600}
          timingFunction="ease"
        >
          {(style) => (
            <Paper
              withBorder
              p="lg"
              radius="md"
              style={{ ...style, padding: "1em", margin: "1em" }}
            >
              <PaymentModal setPaying={setPaying} tokensUsed={tokensUsed} />
              {/* <MonetbilPaymentWidget text={"Deposit Chat Coins"} /> */}
            </Paper>
          )}
        </Transition>
        <Paper
          withBorder
          p="lg"
          radius="md"
          style={{ padding: "1em", margin: "1em" }}
        >
          <Group position="apart">
            <FlexRow style={{ padding: "0px 1em", width: "60vw" }}>
              <FlexColumn alignItems="flex-start">
                <Text
                  c="dimmed"
                  tt="uppercase"
                  fw={700}
                  fz="xs"
                  className={classes.label}
                >
                  Chat Coins Used
                </Text>
                <FlexRow justifyContent="flex-start" alignItems="flex-start">
                  <Text fw={700} fz="xl">
                    {tokensUsed < 100000
                      ? formatAmountWithComma(tokensUsed)
                      : formatAmount(tokensUsed)}
                  </Text>
                  <Text fw={600} fz="xs">
                    CC
                  </Text>
                </FlexRow>
              </FlexColumn>
              <ThemeIcon
                color="gray"
                variant="light"
                sx={(theme) => ({
                  color: theme.colors.red[6],
                })}
                size={38}
                radius="md"
              >
                <IconCoin size="1.8rem" stroke={1.5} />
              </ThemeIcon>
            </FlexRow>
          </Group>
          <FlexColumn
            width="100%"
            style={{ paddingTop: "1em" }}
            //   alignItems="flex-start"
          >
            <Button variant="filled" color="violet" fullWidth disabled>
              Receive Rewards
            </Button>
          </FlexColumn>
        </Paper>

        <Paper
          withBorder
          p="lg"
          radius="md"
          style={{ padding: "1em", margin: "1em" }}
        >
          <Group position="apart">
            <FlexRow
              style={{ padding: "0px 1em", width: "60vw" }}
              justifyContent="space-between"
            >
              <FlexColumn alignItems="flex-start">
                <Text
                  c="dimmed"
                  tt="uppercase"
                  fw={700}
                  fz="xs"
                  className={classes.label}
                >
                  Total Chat Coins
                </Text>
                <FlexRow justifyContent="flex-start" alignItems="flex-start">
                  <Text fw={700} fz="xl">
                    {user?.tokens < 100000
                      ? formatAmountWithComma(user?.tokens)
                      : formatAmount(user?.tokens)}
                  </Text>
                  <Text fw={600} fz="xs">
                    CC
                  </Text>
                </FlexRow>
              </FlexColumn>
              <ThemeIcon
                color="gray"
                variant="light"
                sx={(theme) => ({
                  color: "indigo",
                })}
                size={38}
                radius="md"
              >
                <IconCoin size="1.8rem" stroke={1.5} />
              </ThemeIcon>
            </FlexRow>
          </Group>
          <FlexColumn
            width="100%"
            style={{ paddingTop: "1em" }}
            //   alignItems="flex-start"
          >
            <Tooltip label="You will receive a reward when you deposit total reaches 100,000">
              <Button variant="filled" color="violet" fullWidth disabled>
                Receive Rewards
              </Button>
            </Tooltip>
          </FlexColumn>
        </Paper>
      </FlexColumn>
    </View>
  );
}
