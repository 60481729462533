import { LoadingOverlay, ScrollArea } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import Message from "./Message";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import {
  getMessages,
  loadMoreMessages,
} from "../../store/messages/messageJobs";
import { addMessage, saveMessage } from "../../store/messages/messagesReducer";
import { saveLastMsg } from "../../store/lastMessage/lastMessageReducer";
import { formatDate } from "../../utils/formatDate";
import { setLoading } from "../../store/loading/loadingReducer";

const ChatBox = () => {
  // const activeChat = useSelector((state) => state.activeChat.chat);
  const messages = useSelector((state) => state.messages);
  const prompt = useSelector((state) => state.prompt);
  const user = useSelector((state) => state.user);
  const lastMsg = useSelector((state) => state.lastMessage);
  const loading = useSelector((state) => state.loading);
  const dispatch = useDispatch();
  const viewport = useRef();

  const [loadingMore, setLoadingMore] = useState(false);

  const scrollToBottom = () =>
    viewport.current.scrollTo({
      top: viewport.current.scrollHeight,
      behavior: "smooth",
    });

  const handleInfiniteScroll = async (y) => {
    // if (
    //   window.innerHeight + document.documentElement.scrollTop ===
    //   document.documentElement.offsetHeight
    // ) {
    //   // User has scrolled to the bottom
    //   loadMoreMessages();
    // }
    // console.log("Scrolling");
    let mut = formatDate(lastMsg.timestamp);
    let dat = new Date(mut);
    let fin = {
      ...lastMsg,
      timestamp: dat,
    };
    if (y === 0) {
      console.log("reached the top");
      if (messages.length > 22) {
        setLoadingMore(true);
        try {
          await loadMoreMessages(user.uid, messages.length).then((res) => {
            console.log(res);
            if (res.data.length) {
              dispatch(addMessage(res.data));
              dispatch(saveLastMsg(res.lastMsg));
              setLoadingMore(false);
            } else {
              setLoadingMore(false);
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  useEffect(() => {
    if (messages?.length > 23) {
      return;
    } else {
      scrollToBottom();
    }
  }, [messages?.length]);

  useEffect(() => {
    if (prompt === "text" && messages?.length > 1) {
      dispatch(setLoading(true))
      getMessages(user.uid).then((res) => {
        if (res) {
          dispatch(saveMessage(res.data));
          dispatch(saveLastMsg(res.lastMsg));
        }
      });
      dispatch(setLoading(false));
      scrollToBottom();
    } else if (messages === null) {
      dispatch(setLoading(true))
      getMessages(user.uid).then((res) => {
        if (res) {
          dispatch(saveMessage(res.data));
          dispatch(saveLastMsg(res.lastMsg));
        }
      });
      dispatch(setLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, loading, user.uid]);
  return (
    <Scrollbars>
      <ScrollArea
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          height: "65vh",
          borderRadius: "10px",
          justifyContent: "flex-end",
        }}
        id="msg-box"
        viewportRef={viewport}
        onScrollPositionChange={({ y }) => handleInfiniteScroll(y)}
      >
        {/* <LoadingOverlay
          visible={activeChat?.messages === undefined}
          overlayBlur={2}
          loaderProps={{ size: "sm", color: "violet", variant: "bars" }}
        /> */}
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
          loaderProps={{ size: "sm", color: "violet", variant: "bars" }}
          zIndex={1}
        />
        <LoadingOverlay
          visible={loadingMore}
          title="Loading Older Messages"
          overlayBlur={1}
          loaderProps={{ size: "sm", color: "violet", variant: "bars" }}
          zIndex={1}
        />

        {messages?.length
          ? messages?.map((message, index) => {
            return message.isDeleted ? null : (
              <Message
                key={index}
                // styles={styles}
                mainMessage={message}
                index={index}
              />
            );
          })
          : <div style={{
            width: "100%",
            height: "100%",
          }}>
            No messages to display...
          </div>}
      </ScrollArea>
    </Scrollbars>
  );
};

export default ChatBox;
