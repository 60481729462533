import Chat from "./views/Chat/Chat";
import Diary from "./views/Diary/Diary";
import Error from "./views/Error/Error";
import { Features } from "./views/Features/Features";
import Marketplace from "./views/Marketplace/Marketplace";
import { Wallet } from "./views/Wallet/Wallet";

const routes = [
  {
    path: "/",
    component: <Chat />,
  },
  {
    path: "/activate",
    component: <Features />,
  },
  {
    path: "*",
    component: <Error page={"404"} />,
  },
  {
    path: "/chat",
    component: <Chat />,
  },
  {
    path: "/diary",
    component: <Diary />,
  },
  {
    path: "/onitsha",
    component: <Marketplace />,
  },
  {
    path: "/features",
    component: <Features />,
  },
  // {
  //   path: "/pricing",
  //   component: <Marketplace />,
  // },
  {
    path: "/wallet",
    component: <Wallet />,
  },
  {
    path: "/settings",
    component: <Error page={"CS"} />,
  },
  {
    path: "/terms",
    component: <Error page="TC" />,
  },
];
export default routes;
