import { createSlice } from "@reduxjs/toolkit";

export const storeTextSlice = createSlice({
  name: "storeText",
  initialState: "",
  reducers: {
    setStoreText: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setStoreText } = storeTextSlice.actions;
export default storeTextSlice.reducer;
