import {
  Timestamp,
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
} from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import { formatDate } from "../../utils/formatDate";

export const listenToMessages = (userID) => {
  const q = query(collection(db, "MESSAGES"), where("userID", "==", userID));
  onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        console.log("New Data: ", change.doc.data());
        return change.doc.data();
      }
      if (change.type === "modified") {
        console.log("Modified Data: ", change.doc.data());
        return change.doc.data();
      }
      if (change.type === "removed") {
        console.log("Removed Data: ", change.doc.data());
        return change.doc.data();
      }
    });
  });
};

export const getMessages = async (userID) => {
  const q = query(
    collection(db, "MESSAGES"),
    where("userID", "==", userID),
    orderBy("timestamp", "desc"),
    limit(23)
  );
  const querySnapshot = await getDocs(q);
  const data = [];
  const final = {
    data: data,
    lastMsg: querySnapshot.docs[querySnapshot.docs.length - 1]?.data(),
  };

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.data());
    data.push(doc.data());
  });

  // console.log("Messages Data", data);
  // Comparison function to sort objects by date
  const compareByDate = (a, b) => {
    const dateA = a.timestamp;
    const dateB = b.timestamp;

    // Compare the dates
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };

  // Sort the items array by date
  // data.sort(compareByDate);

  // Output the sorted items
  // console.log(data);
  return final;
};
export const loadMoreMessages = async (userID, total) => {
  const q = query(
    collection(db, "MESSAGES"),
    where("userID", "==", userID),
    orderBy("timestamp", "desc"),
    // startAfter(lastMsg),
    limit(total + 10)
  );
  const querySnapshot = await getDocs(q);
  const data = [];
  const final = {
    data: data,
    lastMsg: querySnapshot.docs[querySnapshot.docs.length - 1]?.data(),
  };

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.data());
    data.push(doc.data());
  });

  // console.log("Messages Data", data);
  // Comparison function to sort objects by date
  console.log("res frm firestore", data);
  const compareByDate = (a, b) => {
    const dateA = a.timestamp;
    const dateB = b.timestamp;

    // Compare the dates
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };

  // Sort the items array by date
  // data.sort(compareByDate);

  // Output the sorted items
  // console.log(data);
  return final;
};
export const createMessage = async (data) => {
  // console.log("Creating Message");
  const now = new Date();
  const final = {
    ...data,
    updateDate: Timestamp.fromDate(now),
  };
  // console.log("Final Message Data:", final);
  await setDoc(doc(db, "MESSAGES", data?.id), {
    ...final,
  });
  return final;
};
export const editMessage = async (data) => {
  // console.log("Editing Chat");
  const now = new Date();
  const final = {
    ...data,
    updateDate: Timestamp.fromDate(now),
  };
  // console.log("Final Data:", final);
  await setDoc(doc(db, "MESSAGES", data?.id), {
    ...final,
  });
  return final;
};
