import { createSlice } from "@reduxjs/toolkit";



export const loadingSlice = createSlice({
  name: "loading",
  initialState: false,
  reducers: {
    setLoading: (state, action) => {

      let data = action.payload;
      state = data
      return state;
    }
  },
});

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
