import { createSlice } from "@reduxjs/toolkit";

export const lastMessageSlice = createSlice({
  name: "lastMessage",
  initialState: {},
  reducers: {
    saveLastMsg: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state = action.payload;
      return state;
    },

    // decremented: (state) => {
    //   state.value -= 1;
    // },
  },
});

export const { saveLastMsg } = lastMessageSlice.actions;
export default lastMessageSlice.reducer;
