import { ActionIcon, useMantineTheme, Textarea } from "@mantine/core";
import { IconArrowRight, IconArrowLeft } from "@tabler/icons-react";
import "./Components.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getRandomSendErrorMessage } from "../../workers/errorMessage";
import { toast } from "react-hot-toast";
import logo from "../../assets/Onitsha.gif";
import { sendMessage } from "../../services/messageService";
import { addMessage, saveMessage } from "../../store/messages/messagesReducer";
import { errorNotify } from "./Notify";
import { setStoreText } from "../../store/storeText/storeTextReducer";
import { setPrompt } from "../../store/prompt/promptReducer";
import {
  getMessages,
  listenToMessages,
} from "../../store/messages/messageJobs";
import { saveLastMsg } from "../../store/lastMessage/lastMessageReducer";
import { setLoading } from "../../store/loading/loadingReducer";

export function MessageInput() {
  const theme = useMantineTheme();

  // const activeChat = useSelector((state) => state.activeChat.chat);
  const user = useSelector((state) => state.user);
  const messages = useSelector((state) => state.messages);
  const text = useSelector((state) => state.storeText);
  const prompt = useSelector((state) => state.prompt);

  // const messages = groupedMessages?.map((msg) => msg.messages);

  const [message, setMessage] = useState(text || "");
  const dispatch = useDispatch();
  // const [loading, setLoading] = useState(false);
  const [tokensUsed, setTokensUsed] = useState(0);

  const getPercent = (val, max) => {
    let ans = 100 - (val / max) * 100;
    // console.log("Chat Percent", parseFloat(ans.toFixed(0)));
    return parseFloat(ans.toFixed(0));
  };
  const CalcTokens = () => {
    let temp = 0;
    messages.map(
      (msg) =>
        // setTokensUsed(tokensUsed + msg.tokensUsed)
        // console.log(msg.tokensUsed);
        (temp = temp + msg.tokensUsed)
    );
    // console.log("Temp is:", messages.length);
    setTokensUsed(temp);

    return tokensUsed;
  };
  const send = async () => {
    if (getPercent(tokensUsed, user?.tokens) > 1) {
      dispatch(setStoreText(""));
      const userName = user.displayName;
      // Global flag required when calling replaceAll with regex
      const regex = / /gi;
      // console.log(userName.replaceAll(regex, "_"));

      const msgToSend = {
        name: userName.replaceAll(regex, "_") || "User",
        role: "user",
        content: text,
      };
      const prevSession = messages?.length
        ? [...messages[messages?.length - 1].messages]
        : [];
      const extra =
        messages?.length > 5
          ? [...messages[messages?.length - 2].messages]
          : [];
      const msgTS =
        prevSession?.length && extra?.length
          ? [...extra, ...prevSession, msgToSend]
          : prevSession?.length
            ? [...prevSession, msgToSend]
            : [msgToSend];

      // console.log("Prev Session", prevSession);
      // console.log("Messages to Send", msgTS);
      const data = {
        // chat: activeChat,
        state: false,
        // chatID: activeChat.id,
        userID: user.uid,
        messages: msgTS,
      };
      dispatch(setPrompt("loading"));
      dispatch(setLoading(true));
      // console.log(data);
      // setLoading(false);
      await sendMessage({ ...data }).then((res) => {
        setMessage("");
        if (res.forState.id?.length) {
          const newMsg = listenToMessages(res.forState.id);
          console.log("New Message", newMsg);
          dispatch(addMessage(newMsg));
        }
        getMessages(user.uid).then((res) => {
          if (res) {
            dispatch(saveMessage(res.data));
            dispatch(saveLastMsg(res.lastMsg));
          }
        });
        dispatch(setPrompt("text"));
        dispatch(setLoading(false));
        return;
      });
    } else {
      errorNotify({
        code: "Energy Low",
        message: "Insufficient Energy. Please Refill Chat Coins",
      });
    }
  };
  useEffect(() => {
    // if (text?.length) {
    //   setMessage(text);
    // }
    if (messages?.length) {
      CalcTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages?.length, text]);
  return (
    <Textarea
      aria-label="Type a Message to ChatGDA"
      autosize
      maxRows={3}
      autoFocus
      disabled={prompt === "loading"}
      icon={
        <img
          src={logo}
          alt="ChatGDA"
          width={32}
          height={32}
          style={{ pointerEvents: "none" }}
        />
      }
      radius="xl"
      size="md"
      spellCheck={true}
      style={{
        width: "90%",
        height: "auto",
        scrollbarWidth: "none",
        marginTop: "1em",
        marginBottom: "1em",
        // borderColor: message?.length ? "indigo !important" : "gray !important",
      }}
      className="messageInput"
      value={text || ""}
      onKeyDown={(e) => {
        if (e.key.match("Enter") && !e.ctrlKey) {
          if (text.length) {
            send();
            dispatch(setStoreText(""));
          } else {
            const msg = getRandomSendErrorMessage();
            toast.error(msg);
          }
        } else if (e.key.match("Enter") && e.ctrlKey) {
          e.preventDefault();
          // setMessage((prevText) => prevText + "\n");
          dispatch(setStoreText(text + "\n"));
        }
      }}
      onChange={(e) => dispatch(setStoreText(e.target.value))}
      rightSection={
        prompt === "text" ? (
          <ActionIcon
            size={32}
            radius="xl"
            style={{
              backgroundColor:
                theme.colorScheme === "dark" ? theme.colors.dark[4] : "#f2e0ff",
              color: theme.colorScheme === "dark" ? "#f2e0ff" : "#000",
              cursor: "pointer",
            }}
            variant="filled"
            className="sendBtn"
            disabled={!text?.length}
            onClick={() => {
              if (text?.length) {
                send();
                dispatch(setStoreText(""));
              } else {
                const msg = getRandomSendErrorMessage();
                toast.error(msg);
              }
            }}
          >
            {theme.dir === "ltr" ? (
              <IconArrowRight size="1.1rem" stroke={1.5} />
            ) : (
              <IconArrowLeft size="1.1rem" stroke={1.5} />
            )}
          </ActionIcon>
        ) : (
          <img src={logo} alt="ChatGDA" width={32} height={32} />
        )
      }
      placeholder="What's on your mind?...."
      rightSectionWidth={42}
    />
  );
}

export default MessageInput;
