import React from "react";
import View from "../View";

const Diary = () => {
  let arr = [1, 2, 3];
  arr.sort();
  return (
    <View link={"/diary"}>
      <div>
        {/* //form with default values */}
        <hr />
        <h2>Diary</h2>
        {/* Table displaying data from redux */}
        <div>
          <tr></tr>
        </div>
      </div>
    </View>
  );
};

export default Diary;
