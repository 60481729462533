import React, { useEffect } from "react";
import View from "../View";
import { FlexColumn, FlexRow } from "../../components/Flex";
import "./Chat.css";
import MessageInput from "../../header/components/MessageInput";
import ChatBox from "../../header/components/ChatBox";
import Scrollbars from "react-custom-scrollbars-2";
import Recommendations from "../../header/components/Recommendations";
import { useState } from "react";
import { loadingNotify } from "../../header/components/Notify";
import { getMessages } from "../../store/messages/messageJobs";
import { saveMessage } from "../../store/messages/messagesReducer";
import { useDispatch, useSelector } from "react-redux";
import { saveLastMsg } from "../../store/lastMessage/lastMessageReducer";

const Chat = () => {
  // const msg = data.chat[0].messages;
  //  const msg = activeChat.chat?.messages;

  const [text, setText] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user?.displayName) {
      let loaded = false;
      loadingNotify({
        state: loaded,
        preCode: "Checking for New Messages....",
        preMessage: "Please Wait....",
        postCode: "Got it",
        postMessage: "Check Complete!",
      });
      getMessages(user.uid).then((res) => {
        if (res) {
          dispatch(saveMessage(res.data));
          dispatch(saveLastMsg(res.lastMsg));
        }
      });
      loaded = true;
      // scrollToBottom();
    }
  }, [dispatch, user?.displayName, user.uid]);

  return (
    <View link={"/chat"}>
      <FlexColumn
        style={{
          height: "inherit",
          width: "100%",
          overflowY: "hidden",
        }}
      >
        <FlexColumn
          style={{
            height: "inherit",
            justifyContent: "flex-start",
          }}
        >
          <FlexRow
            style={{
              width: "100%",
              minHeight: "10vh",
              maxHeight: "70vh",
            }}
          >
            <MessageInput text={text} setText={setText} />
          </FlexRow>
          <Scrollbars style={{ height: "65vh" }}>
            <FlexColumn
              style={{
                height: "65vh",
                width: "100%",
              }}
            >
              <ChatBox />
            </FlexColumn>
          </Scrollbars>
          <FlexColumn style={{ height: "10vh" }}>
            <Recommendations setText={setText} text={text} />
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </View>
  );
};

export default Chat;
