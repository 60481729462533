import React, { useState } from "react";
import "./Marketplace.css";
import View from "../View";
import { FlexColumn, FlexRow } from "../../components/Flex";
import Heading from "../../components/Heading";

const Marketplace = () => {
  return (
    <View link={"/marketplace"}>
      <FlexColumn
        style={{
          height: "90vh",
          width: "98vw",
          margin: "auto",
          padding: "0.5em",
        }}
      >
        <FlexColumn
          style={{
            marginTop: "12vh",
          }}
        >
          <Heading
            style={{
              fontSize: 20,
            }}
          >
            Marketplace
          </Heading>
          <FlexRow
            style={{
              minHeight: "350px",
              width: "inherit",
              overflowX: "scroll",
              overflowY: "hidden",
            }}
            className="customScroll"
          >
            <FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/400/400"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/600/300"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
            </FlexColumn>
            <FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/400/400"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/600/300"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
            </FlexColumn>
            <FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/400/400"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/600/300"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
            </FlexColumn>
            <FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/400/400"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
              <FlexColumn className="marketCard">
                <FlexColumn>
                  <img
                    className="cardImg"
                    src="https://picsum.photos/600/300"
                    alt="Item"
                  />
                </FlexColumn>
                <FlexColumn>
                  <ul>
                    <li>Price: 3500XAF</li>
                    <li>Item: Manyu Canvas Painting</li>
                    <li>Category: Traditional Art</li>
                  </ul>
                </FlexColumn>
              </FlexColumn>
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
      </FlexColumn>
    </View>
  );
};

export default Marketplace;
