import React, { useState } from "react";
import { Button, Popover, Text, Tooltip } from "@mantine/core";
import {
  IconCheck,
  IconEdit,
  IconMicrophone,
  IconSend,
  IconTrashX,
} from "@tabler/icons";
import { IconMicrophoneOff } from "@tabler/icons-react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FlexColumn, FlexRow } from "./Flex";
import { useDispatch, useSelector } from "react-redux";
import { setStoreText } from "../store/storeText/storeTextReducer";
import logo from "../assets/Onitsha.gif";
import { errorNotify } from "../header/components/Notify";
import { setPrompt } from "../store/prompt/promptReducer";
import { sendMessage } from "../services/messageService";
import { addMessage } from "../store/messages/messagesReducer";
import { useEffect } from "react";

const Dictaphone = () => {
  const storeText = useSelector((state) => state.storeText);
  const user = useSelector((state) => state.user);
  const messages = useSelector((state) => state.messages);
  const prompt = useSelector((state) => state.prompt);
  const dispatch = useDispatch();
  // const [popOverOpened, setPopOverOpened] = useState(false);
  const [tokensUsed] = useState(0);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();
  const send = () => {
    if (getPercent(tokensUsed, user?.tokens) > 1) {
      // dispatch(setStoreText(""));
      const userName = user.displayName;
      // Global flag required when calling replaceAll with regex
      const regex = / /gi;
      // console.log(userName.replaceAll(regex, "_"));

      const msgToSend = {
        name: userName.replaceAll(regex, "_") || "User",
        role: "user",
        content: storeText,
      };
      const prevSession = messages?.length
        ? [...messages[messages?.length - 1].messages]
        : [];
      const extra =
        messages?.length > 5
          ? [...messages[messages?.length - 2].messages]
          : [];
      const msgTS =
        prevSession?.length && extra?.length
          ? [...extra, ...prevSession, msgToSend]
          : prevSession?.length
          ? [...prevSession, msgToSend]
          : [msgToSend];

      // console.log("Prev Session", prevSession);
      // console.log("Messages to Send", msgTS);
      const data = {
        // chat: activeChat,
        state: false,
        // chatID: activeChat.id,
        userID: user.uid,
        messages: msgTS,
      };
      dispatch(setStoreText(""));
      dispatch(setPrompt("loading"));
      // console.log(data);
      // setLoading(false);
      sendMessage({ ...data }).then((res) => {
        if (res.forState.id?.length) {
          dispatch(addMessage(res.forState));
        }
        resetTranscript();
        dispatch(setPrompt("text"));
      });
    } else {
      errorNotify({
        code: "Energy Low",
        message: "Insufficient Energy. Please Refill Chat Coins",
      });
    }
  };

  useEffect(() => {
    if (finalTranscript?.length) {
      dispatch(setStoreText(finalTranscript));
    }
    // if (
    //   finalTranscript?.length > 1 &&
    //   transcript?.length === finalTranscript?.length
    // ) {
    //   dispatch(setStoreText(finalTranscript));
    //   send();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening, finalTranscript.length]);

  const getPercent = (val, max) => {
    let ans = 100 - (val / max) * 100;
    // console.log("Chat Percent", parseFloat(ans.toFixed(0)));
    return parseFloat(ans.toFixed(0));
  };

  const startListening = () => {
    SpeechRecognition.startListening({
      continuous: true,
    });
    dispatch(setStoreText(finalTranscript));
    // if (finalTranscript?.length) {
    // }
  };
  const stopListening = async () => {
    // dispatch(setStoreText(finalTranscript));
    if (storeText.length) {
      await SpeechRecognition.stopListening().then((res) => {
        send();
        // console.log("Transcription", finalTranscript);
        // console.log("Res", res);
      });
    }

    dispatch(setStoreText(finalTranscript));
    // storeText?.length
    //   ? dispatch(setStoreText(`${storeText} ${transcript}`))
    //   : dispatch(setStoreText(transcript));
    // resetTranscript();
    // if (storeText.length) {
    // } else {
    //   errorNotify({
    //     code: "Say Something",
    //     message: "Your Microphone Did not catch anything",
    //   });
    // }
  };
  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }
  return (
    <Popover
      opened={listening}
      position="top"
      width="target"
      transitionProps={{ transition: "pop" }}
      zIndex={2000}
    >
      <Popover.Target>
        <div>
          <FlexRow>
            {/* <p style={{ color: "white" }}>{transcript}</p>
      <p>Microphone: {listening ? "on" : "off"}</p> */}
            {listening ? (
              <FlexColumn>
                <Button
                  variant="outline"
                  style={{ borderRadius: 100, borderColor: "indigo" }}
                  onClick={() => {
                    stopListening();
                  }}
                >
                  <IconMicrophone color="indigo" width={"50px"} />
                </Button>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Tap to Send...
                </Text>
              </FlexColumn>
            ) : prompt === "loading" ? (
              <FlexColumn>
                <Button
                  variant="outline"
                  style={{ borderRadius: 100, borderColor: "indigo" }}
                >
                  <img src={logo} alt="ChatGDA" width={32} height={32} />
                </Button>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Replying...
                </Text>
              </FlexColumn>
            ) : (
              <FlexColumn>
                <Button
                  variant="outline"
                  style={{ borderRadius: 100, borderColor: "green" }}
                  onClick={() => {
                    startListening();
                  }}
                  //   storeText?.length
                  //     ? dispatch(setStoreText(`${storeText} ${transcript}`))
                  //     : dispatch(setStoreText(transcript));
                  //   // resetTranscript();
                  // }}
                >
                  {/* <IconMicrophoneOff color="red" width={"50px"} /> */}
                  <IconMicrophone color="green" width={"50px"} />
                </Button>
                <Text style={{ fontSize: "12px", marginTop: "10px" }}>
                  Tap Mic to Speak
                </Text>
              </FlexColumn>
            )}
            {/* <p color="white" style={{ marginLeft: "1em" }}>
        {transcript}
      </p> */}
          </FlexRow>
        </div>
      </Popover.Target>
      <Popover.Dropdown style={{ maxWidth: "300px", width: "auto" }}>
        {listening ? (
          <FlexRow style={{ cursor: "pointer" }}>
            {/* <IconSend
              onClick={() => {
                storeText?.length
                  ? dispatch(setStoreText(`${storeText} ${transcript}`))
                  : dispatch(setStoreText(transcript));
                resetTranscript();
              }}
              width={"30px"}
            /> */}
            <IconTrashX
              onClick={() => {
                SpeechRecognition.abortListening();
                resetTranscript();
                dispatch(setStoreText(""));
              }}
              color="red"
              width={"30px"}
            />
          </FlexRow>
        ) : null}
      </Popover.Dropdown>
    </Popover>
  );
};
export default Dictaphone;
